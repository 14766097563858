/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { ArrowRight, Play, Search } from 'akar-icons';
import { useParams } from 'react-router-dom';
import { Avatar } from 'evergreen-ui';
import {Link} from 'react-router-dom'


import 'swiper/css';
//import "swiper/css/navigation";
//import "swiper/css/pagination";
import '../content/styles/main.css'
import '../content/styles/flexboxgrid.min.css'
import logo from '../content/img/logo.svg'


//import news from './video_content/news/index.news.json'

function ErrorPage() {
  const { id } = useParams()
  const [loading, setLoading] = useState(false);
  const [isNews, setIsNews] = useState([]);



  useEffect(() => {
    if (!loading) {

      axios.get('../../news2.json')
        .then(response => {
          setIsNews(response.data)
          setLoading(true);
       
        })
    }

  }, [loading]);



  useEffect(() => {
    const header = document.getElementById("header");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");

      } else {
        header.classList.remove("sticky");
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);


  return (
    <>
      <div className="content">
        <header id="header">
          <nav className="header_menu">
            <div className="header_menu-left">
              <Link to="/">
                <img src={logo} alt="Пробок.нет" />
              </Link>
            </div>
            <div className="header_menu-right">
              <Link className="f-medium a-hover" to={'/'}>На главную</Link>
            </div>
          </nav>
        </header>
        <section className='section_post'>
          {
            isNews.filter(post => post.id === id).map((filteredpost) =>
              <div className='content_post'>
                <div className='post_header'>
                  <div className='post_items'>
                    <div className='post_item-header'>
                    <Avatar hashValue="id_249" name="Александр Шумский" size={32} marginRight={16} />
                    <p>Александр Шумский</p>
                    </div>
                  </div>
                </div>
                <h1>{filteredpost.title}</h1>
                <div className='post_photo'>
                    <img src={filteredpost.preview} alt={filteredpost.title}/>
                </div>
                <div className='text_post' dangerouslySetInnerHTML={{ __html: filteredpost.text }} />

                <div className='footer_post'>
                    <p className='footer_post-title'>P.S.</p>
                    <p className='footer_post-text'>Подпишитесь на мой телеграм канал <a href='https://t.me/s/proboknet' target='_blank'  without rel="noreferrer">Александр Шумский (@proboknet)</a></p>
                </div>
              </div>
            )

          }
        </section>
      </div>
    </>
  );
}

export default ErrorPage;
