/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { ArrowRight, Play, Search } from 'akar-icons';
import { useParams } from 'react-router-dom';
import { Avatar } from 'evergreen-ui';
import { Link } from 'react-router-dom'
import { Player, ControlBar, PlaybackRateMenuButton, BigPlayButton } from 'video-react';

import 'swiper/css';
//import "swiper/css/navigation";
//import "swiper/css/pagination";
import '../content/styles/main.css'
import '../content/styles/flexboxgrid.min.css'
import logo from '../content/img/logo.svg'


//import news from './video_content/news/index.news.json'

function VideoPage() {

    const { id } = useParams()
    const [loading, setLoading] = useState(false);
    const [isNews, setIsNews] = useState([]);


    useEffect(() => {
        if (!loading) {

            axios.get('../../youtube.json')
                .then(response => {
                    setIsNews(response.data)
                    setLoading(true);
                })
        }

    }, [loading]);



    useEffect(() => {
        const header = document.getElementById("header");
        const sticky = header.offsetTop;
        const scrollCallBack = window.addEventListener("scroll", () => {
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");

            } else {
                header.classList.remove("sticky");
            }
        });
        return () => {
            window.removeEventListener("scroll", scrollCallBack);
        };
    }, []);


    return (
        <>
            <div className="content">
                <header id="header">
                    <nav className="header_menu">
                        <div className="header_menu-left">
                            <Link to="/">
                                <img src={logo} alt="Пробок.нет" />
                            </Link>
                        </div>
                        <div className="header_menu-right">
                            <Link className="f-medium a-hover" to={'/'}>На главную</Link>
                        </div>
                    </nav>
                </header>
                <section className="section_post" >
                    {
                        isNews.filter(video => video.id === id).map((filteredpost) =>
                            <div className="content_post" key={filteredpost.id}>
                                <div className="post_header">
                                    <div className="post_items">
                                        <div className="post_item-header">
                                            <Avatar hashValue="id_249" name="Александр Шумский" size={32} marginRight={16} />
                                            <p>Александр Шумский</p>
                                        </div>
                                    </div>
                                </div>
                                <h1>{filteredpost.title}</h1>
                                <div className="post_video">
                                    <Player poster={'.'+filteredpost.poster} src={'.'+filteredpost.src} playsInline preload="auto">
                                        <BigPlayButton position="center" />
                                        <ControlBar autoHide={true}>
                                            <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                                        </ControlBar>
                                    </Player>
                                </div>
                                <br/>


                                <div className="footer_post">
                                    <p className="footer_post-title">P.S.</p>
                                    <p className="footer_post-text">Подпишитесь на мой телеграм канал <a href="https://t.me/s/proboknet" target="_blank"  rel="noreferrer">Александр Шумский (@proboknet)</a></p>
                                </div>
                            </div>
                        )

                    }
                </section>
            </div>
        </>
    );
}

export default VideoPage;
