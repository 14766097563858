import React, {useRef, useState} from 'react'
import ReactDOM from "react-dom";
import '../content/styles/main.css'
import { Player, ControlBar, PlaybackRateMenuButton, BigPlayButton } from 'video-react';
import { Cross } from 'akar-icons'
import '../content/styles/videoplayer.css'




const TestPage = props => {
    const video = useRef(Player);
    const [isTime, setIsTime] = useState(1)

    const playVideo = (props) => {
        video.current && video.current.pause();
        video.current && video.current.replay(7200);
        props.onClose()
      };


    const CurrentTime = () => {
        video.current && video.CurrentTime(0);
    }
    

    return ReactDOM.createPortal(
        <div className={props.show ? "card_location fade-in active-card" : "card_location"}>
            <div className='card_content'>
                <div className='card_close' onClick={()=> playVideo(props) }><Cross strokeWidth={2} size={24} /></div>
                <Player ref={video} src={props.data.src} playsInline preload='auto' currentTime='34' >
                    <BigPlayButton position="center" />
                    <ControlBar autoHide={true}>
                        <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    </ControlBar>
                </Player>
            </div>
        </div>

        ,
        document.getElementById("root")
    );




};



export default TestPage;

