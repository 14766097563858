import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './routes';



const App = () => {
  return (
      <Router>
        <Routes />
      </Router>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)