/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { ArrowRight, Play, Search } from 'akar-icons';
import { useParams } from 'react-router-dom';
import { SideSheet, Paragraph } from 'evergreen-ui'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper'

import 'swiper/css';
import "swiper/css/navigation";
//import "swiper/css/pagination";
import '../content/styles/main.css'
import '../content/styles/flexboxgrid.min.css'
import logo from '../content/img/logo.svg'
import s1 from '../content/img/achive-slides/1.2.jpg'
import s2 from '../content/img/achive-slides/1.3.jpg'
import s3 from '../content/img/achive-slides/1.4.jpg'
import s4 from '../content/img/achive-slides/2.1.png'
import s5 from '../content/img/achive-slides/2.2.jpg'
import s6 from '../content/img/achive-slides/2.3.jpg'
import s7 from '../content/img/achive-slides/3.1.png'
import s8 from '../content/img/achive-slides/3.2.jpg'
import s9 from '../content/img/achive-slides/3.3.jpg'
import s10 from '../content/img/achive-slides/3.4.png'
import s11 from '../content/img/achive-slides/4.1.jpeg'
import s12 from '../content/img/achive-slides/4.2.jpeg'
import s13 from '../content/img/achive-slides/4.3.jpeg'
import s14 from '../content/img/achive-slides/4.4.jpeg'
import s15 from '../content/img/achive-slides/5.1.jpg'
import s16 from '../content/img/achive-slides/5.2.jpg'
import s17 from '../content/img/achive-slides/5.3.jpg'
import s18 from '../content/img/achive-slides/5.4.jpg'
import s19 from '../content/img/achive-slides/5.5.jpg'
import s20 from '../content/img/achive-slides/5.6.jpg'
import s21 from '../content/img/achive-slides/5.7.jpg'
import s22 from '../content/img/achive-slides/5.8.jpg'
import s23 from '../content/img/achive-slides/5.9.jpg'
import s24 from '../content/img/achive-slides/5.10.jpg'
import s25 from '../content/img/achive-slides/6.1.jpg'
import s26 from '../content/img/achive-slides/6.2.jpg'
import s27 from '../content/img/achive-slides/7.1.jpg'
import s28 from '../content/img/achive-slides/7.2.jpg'
import s29 from '../content/img/achive-slides/8.1.jpg'
import s30 from '../content/img/achive-slides/8.2.jpg'
import s31 from '../content/img/achive-slides/8.3.jpg'
import s32 from '../content/img/achive-slides/9.1.png'
import s33 from '../content/img/achive-slides/10.png'

//import news from './video_content/news/index.news.json'

function AchivePage() {
  const { id } = useParams()
  const [loading, setLoading] = useState(false);
  const [isNews, setIsNews] = useState([]);

  function removeElementByTagName(tagName)
  {
      var element = document.getElementsByTagName(tagName);
      for (let index = element.length - 1; index >= 0; index--)
      {
          element[index].parentNode.removeChild(element[index]);
      }
  }

  useEffect(() => {
    if (!loading) {

      axios.get('../../news2.json')
        .then(response => {
          setIsNews(response.data)
          setLoading(true);

        })

        removeElementByTagName('iframe'); 
    }


  }, [loading]);



  useEffect(() => {
    const header = document.getElementById("header");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");

      } else {
        header.classList.remove("sticky");
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  const [isShownSide, setIsShownSide] = useState(false)

  return (
    <>
      <div className='content'>
        <header id="header">
          <nav className="header_menu">
            <div className="header_menu-left">
              <Link to="/">
                <img src={logo} alt="Пробок.нет" />
              </Link>
            </div>
            <div className="header_menu-right">
              <Link to={'/'} className="f-medium a-hover">На главную</Link>
            </div>
          </nav>
        </header>
        <section className='section_pn achive_page'>
          <div className="container-default">
            <div className='page-section_title'>
              <p className='title'>Наша деятельность</p>
            </div>
            <div className='container-fluid'>
              <div className='row achive-items'>
                <div className='col-xs-12 col-lg-6 col-sm-6'>
                  <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={80}
                    centeredSlides={true}
                    loop={true}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Navigation]}
                    navigation={true}

                    className="mySwiper achive-slider"
                  >
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s1})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s2})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s3})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                  </Swiper>
                  <div className='title-acvive'>
                    <p>Малые типоразмеры знаков</p>
                  </div>
                  <div className='text-achive'>
                    <p>Безопасное и удобное городское пространство благодаря использованию новых малых и совмещенных дорожных знаков.</p>
                  </div>
                </div>
                <div className='col-xs-12 col-lg-6 col-sm-6'>
                  <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={80}
                    centeredSlides={true}
                    loop={true}
                                modules={[Navigation]}
                    navigation={true}
                    pagination={{
                      clickable: true,
                    }}

                    className="mySwiper achive-slider"
                  >
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s4})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s5})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s6})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                  </Swiper>
                  <div className='title-acvive'>
                    <p>Внедрение разметки «вафельница»</p>
                  </div>
                  <div className='text-achive'>
                    <p>Разметка «вафельница» позволяет сократить заторы на загруженных перекрестках, а также улучшить движение общественного транспорта в местах пересечения выделенной полосы с выездами и заездами.</p>
                  </div>
                </div>
                <div className='col-xs-12 col-lg-6 col-sm-6'>
                  <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={80}
                    centeredSlides={true}
                    loop={true}
                                modules={[Navigation]}
                    navigation={true}
                    pagination={{
                      clickable: true,
                    }}

                    className="mySwiper achive-slider"
                  >
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s7})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s8})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s9})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s10})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                  </Swiper>
                  <div className='title-acvive'>
                    <p>Светофор с красным контуром</p>
                  </div>
                  <div className='text-achive'>
                    <p>Хорошая видимость светофора с красной контурной подсветкой дополнительной секции приводит к снижению случайного нарушения правил до нуля.</p>
                  </div>
                </div>
                <div className='col-xs-12 col-lg-6 col-sm-6'>
                  <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={80}
                    centeredSlides={true}
                    loop={true}
                                modules={[Navigation]}
                    navigation={true}
                    pagination={{
                      clickable: true,
                    }}

                    className="mySwiper achive-slider"
                  >
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s11})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s12})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s13})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s14})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                  </Swiper>
                  <div className='title-acvive'>
                    <p>Парклеты на улицах</p>
                  </div>
                  <div className='text-achive'>
                    <p>Временные конструкции устанавливаются на улицах с узкими тротуарами, чтобы расширить пространство для пешеходов. Тут можно посидеть за ноутбком, перекусить или провести встречу с друзьями</p>
                  </div>
                </div>
                <div className='col-xs-12 col-lg-6 col-sm-6'>
                  <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={80}
                    centeredSlides={true}
                    loop={true}
                                modules={[Navigation]}
                    navigation={true}
                    pagination={{
                      clickable: true,
                    }}

                    className="mySwiper achive-slider"
                  >
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s15})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s16})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s17})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s18})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s19})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s20})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s21})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s22})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s23})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s24})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                  </Swiper>
                  <div className='title-acvive'>
                    <p>Устранение дорожных парадоксов</p>
                  </div>
                  <div className='text-achive'>
                    <p>
Устранение дорожных парадоксов
Устранение дорожных парадоксов приводит к сокращению пробок за счет открытия перекрытых мостов, заблокированных дорог и заброшенных подэстакадных пространств.</p>
                  </div>
                </div>
                <div className='col-xs-12 col-lg-6 col-sm-6'>
                  <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={80}
                    centeredSlides={true}
                    loop={true}
                                modules={[Navigation]}
                    navigation={true}
                    pagination={{
                      clickable: true,
                    }}

                    className="mySwiper achive-slider"
                  >
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s25})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s26})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                  </Swiper>
                  <div className='title-acvive'>
                    <p>Диагональные переходы</p>
                  </div>
                  <div className='text-achive'>
                    <p> Диагональный переход экономит пешеходам время и сокращает расстояние, которое им необходимо преодолеть.</p>
                  </div>
                </div>
                <div className='col-xs-12 col-lg-6 col-sm-6'>
                  <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={80}
                    centeredSlides={true}
                    loop={true}
                                modules={[Navigation]}
                    navigation={true}
                    pagination={{
                      clickable: true,
                    }}

                    className="mySwiper achive-slider"
                  >
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s27})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s28})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                  </Swiper>
                  <div className='title-acvive'>
                    <p> Приоритет на круговом движении</p>
                  </div>
                  <div className='text-achive'>
                    <p>Движение на кругу стало главным по всей стране. Это позволяет увеличить пропускную способность перекрестка и снизить количество аварий.</p>
                  </div>
                </div>
                <div className='col-xs-12 col-lg-6 col-sm-6'>
                  <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={80}
                    centeredSlides={true}
                    loop={true}
                                modules={[Navigation]}
                    navigation={true}
                    pagination={{
                      clickable: true,
                    }}

                    className="mySwiper achive-slider"
                  >
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s29})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s30})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s31})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                  </Swiper>
                  <div className='title-acvive'>
                    <p>Реконструкция Покровка, Маросейка, Пятницкая</p>
                  </div>
                  <div className='text-achive'>
                    <p>Пробок.нет разработал концепцию реконструкции улиц, благодаря чему в них удалось вдохнуть новую жизнь, повысить комфорт и безопасность жителей.</p>
                  </div>
                </div>
                <div className='col-xs-12 col-lg-6 col-sm-6'>
                  <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={80}
                    centeredSlides={true}
                    loop={true}
                                modules={[Navigation]}
                    navigation={false}
                    pagination={{
                      clickable: true,
                    }}

                    className="mySwiper achive-slider"
                  >
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s32})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                  </Swiper>
                  <div className='title-acvive'>
                    <p>Временные знаки на желтом фоне</p>
                  </div>
                  <div className='text-achive'>
                    <p>Желтый фон улучшает видимость временных знаков, а также дает четкий сигнал водителям, что ограничения носят временный характер.</p>
                  </div>
                </div>
                <div className='col-xs-12 col-lg-6 col-sm-6'>
                  <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={80}
                    centeredSlides={true}
                    loop={true}
                                modules={[Navigation]}
                    navigation={false}
                    pagination={{
                      clickable: true,
                    }}

                    className="mySwiper achive-slider"
                  >
                    <SwiperSlide>
                      <div className='def-swiper' style={{
                        backgroundImage: `url(${s33})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%',

                      }}></div>
                    </SwiperSlide>
                  </Swiper>
                  <div className='title-acvive'>
                    <p>Скорость для мотоциклистов</p>
                  </div>
                  <div className='text-achive'>
                    <p>Мотоциклистам увеличили разрешенную скорость на с 90 до 110 км/ч.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SideSheet
          isShown={isShownSide}
          onCloseComplete={() => setIsShownSide(false)}
        >
          <Paragraph margin={40}>
            <div className='sideMenu'>
              <a href='#achive'>Наши достижения</a>
              <a href='#activity'>Наша деятельность</a>
              <a href='#socialmedia'>Социальная реклама</a>
              <a href='#media'>Медиа</a>
              <a href='#sotrudnichst'>Сотрудничество</a>
              <p onClick={() => setIsShownSide(false)} className='close_menu'>Закрыть</p>
            </div>
          </Paragraph>
        </SideSheet>
      </div>
    </>
  );
}

export default AchivePage;
