/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from 'axios';
import { ArrowRight, Play } from 'akar-icons';
import { CornerDialog, SideSheet, Paragraph, Spinner } from 'evergreen-ui'
import { Link } from 'react-router-dom';



import 'swiper/css';
import "swiper/css/navigation";
import { Navigation } from 'swiper'

//import "swiper/css/pagination";
import '../content/styles/main.css'
import '../content/styles/flexboxgrid.min.css'
import logo from '../content/img/logo.svg'
import iconTikTok from '../content/img/tiktok.svg'
import iconYoutube from '../content/img/youtube.svg'
import iconTelegram from '../content/img/telegram.svg'
import iconVk from '../content/img/vk.svg'
import o1 from '../content/img/achivements/1.webp'
import o2 from '../content/img/achivements/2.webp'
import o3 from '../content/img/achivements/3.webp'
import o4 from '../content/img/achivements/4.webp'
import o5 from '../content/img/achivements/5.webp'
import o6 from '../content/img/achivements/6.webp'
import s1 from '../content/img/slide/1.jpg'
import s2 from '../content/img/slide/2.webp'
import s3 from '../content/img/slide/3.webp'
import s4 from '../content/img/slide/4.webp'
import s5 from '../content/img/slide/5.webp'
import s6 from '../content/img/slide/6.webp'
import s7 from '../content/img/slide/7.webp'
import s8 from '../content/img/slide/8.webp'
import s9 from '../content/img/slide/9.png'
import s10 from '../content/img/slide/10.webp'

import r1 from '../content/img/slide/1_r.webp'
import r2 from '../content/img/slide/2_r.webp'
import r3 from '../content/img/slide/3_r.webp'
import r4 from '../content/img/slide/4_r.webp'
import r5 from '../content/img/slide/5_r.webp'
import r6 from '../content/img/slide/6_r.webp'
import r7 from '../content/img/slide/7_r.webp'
import r8 from '../content/img/slide/8_r.jpg'
import r9 from '../content/img/slide/9_r.jpg'
import r10 from '../content/img/slide/10_r.jpg'
import r11 from '../content/img/slide/11_r.jpg'
import r12 from '../content/img/slide/12_r.jpg'
import r13 from '../content/img/slide/13_r.jpg'
import r14 from '../content/img/slide/24_r.jpg'
import MyModal from './TestPage';
import MyModal2 from './ShortsPage';

//import news from './video_content/news/index.news.json'

function MainPage() {
  const [isShownSide, setIsShownSide] = useState(false)
  const [iSshowModal, setShowModal] = useState(false);
  const [iSshowModalShorts, setShowModalShorts] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isNews, setIsNews] = useState([])
  const [isTikTok, setIsTikTok] = useState([])
  const [isYoutube, setIsYoutube] = useState([])

  const [modalData, setDataModal] = useState([]);
  const [modalDataShorts, setDataModalShorts] = useState([]);

 function removeElementByTagName(tagName)
  {
      var element = document.getElementsByTagName(tagName);
      for (let index = element.length - 1; index >= 0; index--)
      {
          element[index].parentNode.removeChild(element[index]);
      }
  }
  
  


  useEffect(() => {
    if (!loading) {

      axios.get('../../news2.json')
        .then(response => {
          //console.log(response.data)
          setIsNews(response.data)
          setLoading(true);
        })

      axios.get('../../youtube.json')
        .then(response => {
          //console.log(response.data)
          setIsYoutube(response.data)
          setLoading(true);
        })

      axios.get('../../tiktok.json')
        .then(response => {
          //console.log(response.data)
          setIsTikTok(response.data)
          setLoading(true);
        })
        removeElementByTagName('iframe')

    }

  }, [loading]);






  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }



  useEffect(() => {
    const header = document.getElementById("header");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");

      } else {
        header.classList.remove("sticky");
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  return (
    <>
      <div className="content">
        <MyModal onClose={() => setShowModal(false)} show={iSshowModal} data={modalData}>
        </MyModal>
        <MyModal2 onClose={() => setShowModalShorts(false)} show={iSshowModalShorts} data={modalDataShorts}>
        </MyModal2>
        <header id="header">
          <nav className="header_menu">
            <div className="header_menu-left">
              <Link to="/">
                <img src={logo} alt="Пробок.нет" />
              </Link>
            </div>
            <div className="header_menu-right">
              <a className="f-medium a-hover" onClick={() => setIsShownSide(true)}>Меню</a>
            </div>
          </nav>
        </header>

        <section className='header_banner'>
          <div className="container-default">
            <div className='row'>
              <div className='header_banner-text  col-xs-12 col-sm-6 col-lg-6'>
                <div className='h-text'>
                  <h1 className='f-bold fade-in-top white-banner'>Создаем удобное дорожное движение</h1>
                  <p className='f-normal fade-in white-banner'>Экспертный центр Пробок.нет более 10 лет создает удобное дорожное движение, в приоритете которого комфорт и безопасность человека.</p>
                  <div className='social_media-banner'>
                    <a href="https://www.youtube.com/channel/UCV4CO1uAUm_R32l0L3LM12Q" className='social_media-link'>
                      <img className='fade-in' src={iconYoutube} alt='YouTube' />
                    </a>
                    <a href="https://t.me/Proboknet" className='social_media-link'>
                      <img className='fade-in' src={iconTelegram} alt='Telegram' />
                    </a>
                    <a href="https://vk.com/proboknet" className='social_media-link' >
                      <img className='fade-in' src={iconVk} alt='vk' />
                    </a>
                    <a href="https://www.tiktok.com/@proboknet" className='social_media-link' >
                      <img className='fade-in' src={iconTikTok} alt='tiktok' />
                    </a>
                  </div>
                </div>
              </div>
              <div className={'header_banner-video_' + getRandomInt(1, 3) + ' col-xs-12 col-sm-6 col-lg-6'}>
                {/* <video poster="./content/img/poster.png" autoplay loop muted playsinline >
                        <source src={video}  type='video/webm'/>
                          Тег video не поддерживается вашим браузером.
                        </video> */}
              </div>
            </div>
          </div>
        </section>
        <section className='shumsky_banner'>
          <div className="container-default">
            <div className='row'>
              <div className='shumsky_banner-text col-xs-12  col-lg-6'>
                <div className='h-text'>
                  <h1 className='f-bold'>Руководитель Пробок.нет Александр Шумский</h1>
                  <p className='f-normal'>Экспертный центр Пробок.нет более 10 лет создает удобное дорожное движение, в приоритете которого комфорт и безопасность человека.</p>

                </div>
              </div>
              <div className='shumsky_banner-img col-xs-12  col-lg-6'>
              </div>
            </div>
          </div>
        </section>
        <section id='achive' className='section_pn achivements'>
          <div className="container-default">
            <div className='section_title'>
              <p className='title'>Наши достижения</p>
            </div>
            <div className='section_content'>
              <div className='achivement_items'>
                <div className='row'>
                  <div className='col-xs-6 col-sm-6 col-lg-6'>
                    <p className='achive_item'><b>—</b>Уменьшенные дорожные знаки</p>
                    <p className='achive_item'><b>—</b>Внедрение разметки «вафельница»</p>
                    <p className='achive_item'><b>—</b>Светофор с красным контуром</p>
                    <p className='achive_item'><b>—</b>Парклеты на улицах</p>
                    <p className='achive_item'><b>—</b>Устранение дорожных парадоксов</p>
                  </div>
                  <div className='col-xs-6 col-sm-6 col-lg-6'>
                    <p className='achive_item'><b>—</b>Диагональные пешеходные переходы</p>
                    <p className='achive_item'><b>—</b>Приоритет на круговом движении</p>
                    <p className='achive_item'><b>—</b>Реконструкция улиц Покровка, Маросейка, Пятницкая</p>
                    <p className='achive_item'><b>—</b>Временные знаки на желтом фоне</p>
                    <p className='achive_item'><b>—</b>Скорость для мотоциклистов</p>
                  </div>
                </div>
              </div>
              <div className='section_button'>
                <Link to='/achive' className='sb_link-underline'>Подробнее</Link>
              </div>
            </div>
          </div>
          <div className='swiper_slider-section'>

            <Swiper
              slidesPerView={'auto'}
              spaceBetween={80}
              centeredSlides={true}
              loop={true}
              modules={[Navigation]}
              navigation={true}
              pagination={{
                clickable: true,
              }}
              // breakpoints={{
              //   // when window width is >= 640px
              //   1440: {
              //     width: 860,
              //     slidesPerView: 1,
              //   },
              //   // when window width is >= 768px
              //   768: {
              //     width: 768,
              //     slidesPerView: 2,
              //   },
              // }}
              // modules={[Pagination]}
              className="mySwiper"
            >
              <SwiperSlide className='def-swiper'>
                <div style={{
                  backgroundImage: `url(${s1})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  width: '100%',
                  height: '556px'

                }}></div>
              </SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url("${s2}")`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'

              }}></div></SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url(${s3})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'

              }}></div></SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url(${s4})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'

              }}></div></SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url(${s5})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'

              }}></div></SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url(${s6})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'

              }}></div></SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url(${s7})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'

              }}></div></SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url(${s8})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'

              }}></div></SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url(${s9})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'

              }}></div></SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url(${s10})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'
              }}></div></SwiperSlide>

            </Swiper>
          </div>
        </section>
        <section id='activity' className='section_pn achivements'>
          <div className="container-default">
            <div className='section_title'>
              <p className='title'>Наша деятельность</p>
              <p className='pre-text'>
                По предложениям центра было реализовано более 700 мероприятий. Экспертный центр «Пробок.нет» инициировал и провел 4 федеральных эксперимента в области дорожного движения. На базе Пробок.нет при РОССТАНДАРТе создан Проектный технический комитет 703 «Удобная дорога».
                <br />   <br />
                Так же мы предлагаем внедрить в России <u>новую концепцию</u> удобного дорожного движения, которая позволит сохранить сотни тысяч жизней.
              </p>
            </div>
            <div className='circle_items'>
              <div className='row'>
                <div className=' cirlce-item col-xs-6 col-sm-3 col-lg-4'>
                  <img src={o1} alt='1' />
                </div>
                <div className='cirlce-item  col-xs-6 col-sm-3 col-lg-4'>
                  <img src={o2} alt='1' />
                </div>
                <div className='cirlce-item  col-xs-6 col-sm-3 col-lg-4'>
                  <img src={o3} alt='1' />
                </div>
                <div className=' cirlce-item col-xs-6 col-sm-3 col-lg-4'>
                  <img src={o4} alt='1' />
                </div>
                <div className='cirlce-item  col-xs-6 col-sm-3 col-lg-4'>
                  <img src={o5} alt='1' />
                </div>
                <div className='cirlce-item  col-xs-6 col-sm-3 col-lg-4'>
                  <img src={o6} alt='1' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id='socialmedia' className='section_pn achivements'>
          <div className="container-default">
            <div className='section_title'>
              <p className='title'>Социальная реклама</p>
              <p className='pre-text'>
                С целью популяризации своих идей Пробок.нет проводит масштабные рекламные кампании, повышая тем самым осознанность поведения на дороге и, как следствие, увеличивая безопасность.
              </p>
            </div>
          </div>
          <div className='swiper_slider-section'>
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={80}
              centeredSlides={true}
              loop={true}
              pagination={{
                clickable: true,
              }}
              modules={[Navigation]}
              navigation={true}

              className="mySwiper"
            >
              <SwiperSlide className='def-swiper'>
                <div style={{
                  backgroundImage: `url(${r1})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  width: '100%',
                  height: '556px'
                }}></div>
              </SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url(${r2})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'
              }}></div></SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url(${r3})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'
              }}></div></SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url(${r4})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'
              }}></div></SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url(${r5})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'
              }}></div></SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url(${r6})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'
              }}></div></SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url(${r7})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'
              }}></div></SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url(${r8})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'
              }}></div></SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url(${r9})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'
              }}></div></SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url(${r10})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'
              }}></div></SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url(${r11})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'
              }}></div></SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url(${r12})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'
              }}></div></SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url(${r13})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'
              }}></div></SwiperSlide>
              <SwiperSlide className='def-swiper'> <div style={{
                backgroundImage: `url(${r14})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%',
                height: '556px'
              }}></div></SwiperSlide>

            </Swiper>
          </div>
        </section>
        <section id='media' className='section_pn achivements'>
          <div className="container-default">
            <div className='section_title'>
              <div className='title_group'>
                <p className='title'>Наши серии</p>
                <p className='title_info'><span>листай или свайпай вправо</span><ArrowRight /></p>
              </div>
            </div>
            <div className='reals_slider'>
              <Swiper
                slidesPerView={'auto'}
                spaceBetween={24}
                initialSlide={0}
                navigation={false}
                className="mySwiper"
              >

                {
                  isTikTok.map((tiktok, i) =>

                    <SwiperSlide className='test' key={i} tabIndex={i}>
                      <div className='item_reals'>
                        {<img src={tiktok.preview} alt='probok.net' />}
                        <a className='playVideo' onClick={() => { setShowModalShorts(true); setDataModalShorts(tiktok) }}><Play /></a>
                        {/* <p className='videoName'>Серия № {}</p> */}
                      </div>
                    </SwiperSlide>
                  )
                }

              </Swiper>
            </div>
          </div>
        </section>
        <section className='section_pn achivements' id='youtube'>
          <div className="container-default">
            <div className='section_title'>
              <div className='title_group'>
                <p className='title'>Мы в youtube</p>
                <p className='title_info'><span>листай или свайпай вправо</span><ArrowRight /></p>
              </div>
            </div>

            <div className='youtube_slider'>
              <Swiper
                slidesPerView={'auto'}
                spaceBetween={24}
                initialSlide={0}
                navigation={false}
                className="mySwiper"

              >


                {
                  isYoutube.map((video, i) =>
                    <SwiperSlide key={i} tabIndex={i} className='test' style={{ display: 'flex', alignItems: 'self-start' }}>
                      <div className='item_youtube'
                        style={{
                          background: `linear-gradient(rgba(113, 113, 113, 0.16), rgba(113, 113, 113, 0.16)), url(${video.poster}), no-repeat`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          width: '100%',
                          height: '100%',
                          maxHeight: '232px',

                        }}
                      >
                        <p className='playVideo' onClick={() => { setShowModal(true); setDataModal(video) }}><Play /></p>
                        <Link to={'/video/' + video.id} className='videoName'>{video.title}</Link>
                      </div>
                    </SwiperSlide>
                  )
                }


                {/* <SwiperSlide className='test'>
                  <div className='item_youtube'>
                    <a className='playVideo' href='#/' onClick={() => setShowModal(true)}><Play /></a>
                    <p className='videoName'>Название видео</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide className='test'>
                  <div className='item_youtube'>
                    <a className='playVideo' href='#/'><Play /></a>
                    <p className='videoName'>Название видео</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide className='test'>
                  <div className='item_youtube'>
                    <a className='playVideo' href='#/'><Play /></a>
                    <p className='videoName'>Название видео</p>
                  </div>
                </SwiperSlide> */}


              </Swiper>
            </div>
          </div>
        </section>
        <section className='section_pn achivements'>
          <div className="container-default">
            <div className='section_title'>
              <div className='title_group'>
                <p className='title'>Популярные материалы</p>
                <p className='title_info'><span>листай или свайпай вправо</span><ArrowRight /></p>
              </div>
            </div>
            <div className='news_slider'>
              <Swiper
                slidesPerView={'auto'}
                spaceBetween={24}
                initialSlide={0}
                navigation={false}
                className="mySwiper"
              >
                {
                  isNews.map((news, i) =>
                    <SwiperSlide key={i} tabIndex={i} className='test'>
                      <div className='item_news' style={{
                        background: `radial-gradient(rgba(0,0,0,0.32), rgba(0,0,0,0.32)), url(${news.preview}), no-repeat`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundBlendMode: 'gray',
                        backgroundPosition: 'center',
                        width: '100%',
                        height: '100%',
                        maxHeight: '232px',

                      }}>
                        <a>

                        </a>
                        <p className='newsName'>{news.title}</p>
                        <Link className='news-next' to={'/post/' + news.id}>Читать материал</Link>
                      </div>
                    </SwiperSlide>
                  )
                }
              </Swiper>
            </div>
          </div>
        </section>
        <section id='sotrudnichst' className='section_pn achivements'>
          <div className="container-default">
            <div className='section_title'>
              <p className='title'>Сотрудничество</p>
              <p className='pre-text'>
                Пробок.нет всегда в поисках интересных решений в области удобства дорожного движения. Если у вас есть важный информационный повод для освещения в наших социальных сетях либо инновационное техническое решение – напишите нам: <a href="mailto:info@probok.net">info@probok.net</a>
              </p>
            </div>
          </div>
        </section>
        <footer>
          <div className='footer-title'><p>Контакты</p></div>
          <div className='footer-content'>
            <div className='row'>
              <div className='col-lg-12'>ул. Павла Андреева, д. 4, 115093, Москва</div>
              <div className='col-lg-6'>Тел.: +7(499)236-56-22</div>
              <div className='col-lg-6'>Почта: info@probok.net</div>
            </div>
          </div>
        </footer>
      </div>


      {/* <CornerDialog
        title="Подпишись на наш телеграм канал"
        isShown={isShown}
        onCloseComplete={() => setIsShown(true)}
        confirmLabel="Подписаться"
        cancelLabel="Закрыть"
        onConfirm={() => window.open('https://t.me/proboknet', '_blank')}
      >
        <p className='dialogText'>Сообщество думающих водителей и пешеходов
          <br />
          Канал автоюриста. ☑️ Подписывайтесь
          <br />
          Тут я разбираю штрафы/разводы/ автоподставы/дтп и гаишников
        </p>

      </CornerDialog> */}


      <SideSheet
        isShown={isShownSide}
        onCloseComplete={() => setIsShownSide(false)}
      >
        <Paragraph margin={40}>
          <div className='sideMenu'>
            <a href='#achive'>Наши достижения</a>
            <a href='#activity'>Наша деятельность</a>
            <a href='#socialmedia'>Социальная реклама</a>
            <a href='#media'>Медиа</a>
            <a href='#sotrudnichst'>Сотрудничество</a>
            <p onClick={() => setIsShownSide(false)} className='close_menu'>Закрыть</p>
          </div>
        </Paragraph>
      </SideSheet>
    </>
  );
}

export default MainPage;
