import React from 'react'
import { Routes, Route } from 'react-router-dom'

import MainPage from './pages/MainPage'
import PostPage from './pages/PostPage'
import VideoPage from './pages/VideoPage'
import AchivePage from './pages/AchivePage'
import ErrorPage from './pages/ErrorPage'



const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<MainPage/>} />
      <Route path="/post/:id" element={<PostPage/>} />
      <Route path="/video/:id" element={<VideoPage/>} />
      <Route path="/achive" element={<AchivePage/>} />
      <Route path="/error" element={<ErrorPage/>} />
      <Route path="*" element={<MainPage/>} />
    </Routes>
  )
}
export default Router